import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {StateStorageService} from 'app/core/auth/state-storage.service';
import {Principal} from 'app/core/auth/principal.service';

import {Store} from '@ngxs/store';
import {GetConfig} from 'app/blocks/store/actions/common-actions';
import {AlertService} from 'app/common/alert/alert.service';
import {RoleService} from 'app/blocks/service/api/role.service';
import {LoginService} from 'app/core/login/login.service';
import {is} from 'date-fns/locale';

@Injectable()
export class UserRouteAccessService {
    constructor(
        private store: Store,
        private router: Router,
        private principal: Principal,
        private stateStorageService: StateStorageService,
        private _alertService: AlertService,
        private roleService: RoleService
    ) {
        this.store.dispatch(new GetConfig());
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        const authorities = route.data['authorities'];
        const roleKey = route.data['roleKey'];
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, roleKey, state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    checkLogin(authorities: string[], roleKey: string, url: string): Promise<boolean> {
        // console.log('Checking if the user has authorities...');
        const principal = this.principal;
        return Promise.resolve(
            principal.identity().then((account) => {
                // console.log('User is logged in...');
                if (!authorities || authorities.length === 0) {
                    // console.log('No special auth required...');
                    return true;
                }

                if (account) {
                    return principal.hasAnyAuthority(authorities).then((response) => {
                        if (response) {
                            if (roleKey) {
                                const roleSettings = this.roleService.roleSettings.getValue();

                                if (typeof roleSettings != 'object' || Object.keys(roleSettings).length <= 0) {
                                    return this.roleService.getTenantMenu().then((roleSettings) => {
                                        this.roleService.roleSettings.next(roleSettings);
                                        if (typeof roleSettings[roleKey] === 'boolean' && !roleSettings[roleKey]) {
                                            this._alertService.showError('You do not have access to this page', 'Insufficient Role');
                                            return false;
                                        }

                                        if (authorities.includes('ROLE_CUSTOMER')) {
                                            return principal.hasAnyAuthority(['ROLE_CUSTOMER']).then((isCustomer) => {
                                                if (isCustomer && roleSettings['client-portal-access'] == false) {
                                                    this._alertService.showError('You do not have access to Client Portal', 'Insufficient Role');
                                                    return false;
                                                }
                                                return true;
                                            });
                                        }
                                        return true;
                                    });
                                } else {
                                    if (typeof roleSettings[roleKey] === 'boolean' && !roleSettings[roleKey]) {
                                        this._alertService.showError('You do not have access to this page', 'Insufficient Role');
                                        return false;
                                    }

                                    if (authorities.includes('ROLE_CUSTOMER')) {
                                        return principal.hasAnyAuthority(['ROLE_CUSTOMER']).then((isCustomer) => {
                                            if (isCustomer && roleSettings['client-portal-access'] == false) {
                                                this._alertService.showError('You do not have access to Client Portal', 'Insufficient Role');
                                                return false;
                                            }
                                            return true;
                                        });
                                    }
                                    return true;
                                }
                            }
                            // console.log('Has required auth...');
                            return true;
                        }
                        if (isDevMode()) {
                            console.error('User has not any of required authorities: ', authorities);
                        }
                        this._alertService.showError('You do not have access to this page', 'Insufficient Authority');
                        return false;
                    });
                }

                this.stateStorageService.storeUrl(url);
                this.router.navigate(['login']).then(() => console.log('Redirecting to login'));
                // this.router.navigate(['accessdenied']).then(() => {
                //     // only show the login dialog, if the user hasn't logged in yet
                //     if (!account) {
                //         // this.loginModalService.open();
                //     }
                // });
                return false;
            })
        );
    }
}
